//SINGLE School

import React from "react"
import { graphql, Link } from 'gatsby'
import Img from "gatsby-image"
import Wrapper from "../components/layout/wrapper"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Style from "./school.module.scss"

const SchoolTemplate = ( { data, pageContext: { school } } ) => {
    const upperCaseSchool = school.replace(/^\w/, c => c.toUpperCase());
    return (
        <Layout>
        <SEO title={upperCaseSchool} />
        <section className="page-hero">
          <Wrapper>
            <h1>{upperCaseSchool}</h1>
            <p>There {data.allPrismicListing.totalCount === 1 ? 'is' : 'are'} {data.allPrismicListing.totalCount} available {data.allPrismicListing.totalCount === 1 ? 'listing' : 'listings'}</p>
          </Wrapper>
        </section>
        <Wrapper>

            <section className={Style.schoolSection}>
            <div>
              <div className={Style.listings}>
              {data.allPrismicListing.edges.map(x => (
                       <div className={Style.listing} key={x.node.slugs}>
                           <Link className={Style.listingLink} to={x.node.slugs}>
                            <Img fluid={x.node.data.image_gallery.frontOfHouse.localFile.childImageSharp.fluid} />
                            <div className={Style.listingType}>{x.node.data.listing_type}</div>
                            <p>
                              {x.node.data.title.text.length <= 35 ? x.node.data.title.text : x.node.data.title.text.slice(0, 35) + '...'}
                            </p>
                            <div className={Style.listingPrice}>
                              <small>From <strong>A${x.node.data.starting_rent}</strong> per week</small>
                            </div>
                           </Link>
                       </div>
                   ))}
              </div>
            </div>
               {data.allPrismicListing.totalCount === 0 ? 
                <p>
                  There are no available properties at the moment. 
                  Please sent an email to <a href="mailto:someone@yoursite.com?subject=Help find">hello@staypal.com.au</a> and our team will be in contact to help you.
                </p>
                : null
                }
            </section>
        </Wrapper>
      </Layout>
    )
}

export default SchoolTemplate

export const query = graphql`
query SchoolListQuery ($school: String!) {
    allPrismicListing(filter: {data: {nearby_schools: {elemMatch: {school: {document: {elemMatch: {data: {school: {eq: $school}}}}}}}}}) {
      totalCount
      edges {
        node {
          slugs
          data {
            starting_rent
            listing_type
            title {
              text
            }
            image_gallery {
              frontOfHouse {
                localFile {
                  childImageSharp {
                    fluid (maxHeight: 400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`